export const apiUrl = "https://api.messnow.com//api/";

export const API_URL_V1 = "https://api.messnow.com/api";
// export const API_URL_V2 = "https://api.messnow.com/api/coca";
export const API_URL_V2 = "https://coca-store.com/api";
// export const API_URL_V2 = "http://localhost:8080/api";

export const requestHeader = {
  headers: {
    Accept: "Application/json",
  },
};
