export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";

export const GET_GENERAL_DATA = "GET_GENERAL_DATA";
export const GET_GENERAL_DATA_SUCCESS = "GET_GENERAL_DATA_SUCCESS";

export const GET_ONE_PRODUCT = "GET_ONE_PRODUCT";
export const GET_ONE_PRODUCT_SUCCESS = "GET_ONE_PRODUCT_SUCCESS";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const MOST_VIEW = "MOST_VIEW";
export const MOST_VIEW_SUCCESS = "MOST_VIEW_SUCCESS";

export const HEADER_TITLE = "HEADER_TITLE";

export const POST_INFORMATION_DELIVERY_USER = "POST_INFORMATION_DELIVERY_USER";
export const POST_INFORMATION_DELIVERY_USER_SUCCESS = "POST_INFORMATION_DELIVERY_USER_SUCCESS";

export const GET_INFORMATION_DELIVERY_USER = "GET_INFORMATION_DELIVERY_USER";
export const GET_INFORMATION_DELIVERY_USER_SUCCESS = "GET_INFORMATION_DELIVERY_USER_SUCCESS";

export const TARGET_ONE_INFORMATION_DELIVERY_USER = "TARGET_ONE_INFORMATION_DELIVERY_USER";

export const PUT_INFORMATION_DELIVERY_USER = "PUT_INFORMATION_DELIVERY_USER";
export const PUT_INFORMATION_DELIVERY_USER_SUCCESS = "PUT_INFORMATION_DELIVERY_USER_SUCCESS";

export const DELETE_DELIVERY_USER = "DELETE_DELIVERY_USER";
export const DELETE_DELIVERY_USER_SUCCESS = "DELETE_DELIVERY_USER_SUCCESS";

export const RESET_MODAL_POPUP = "RESET_MODAL_POPUP";

export const CHECK_HANDLE_GET_DELIVERY_USER = "CHECK_HANDLE_GET_DELIVERY_USER";

export const GET_PROMOTION_VOUCHERS = "GET_PROMOTION_VOUCHERS";
export const GET_PROMOTION_VOUCHERS_SUCCESS = "GET_PROMOTION_VOUCHERS_SUCCESS";

export const GET_CODE_PROMOTION = "GET_CODE_PROMOTION";

export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";

export const GET_TITLE_CATEGORIES = "GET_TITLE_CATEGORIES"

export const APPLY_PROMOTION = "APPLY_PROMOTION";
export const APPLY_PROMOTION_SUCCESS = "APPLY_PROMOTION_SUCCESS";

export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";

export const GET_SHIPPING_FEE = "GET_SHIPPING_FEE";
export const GET_SHIPPING_FEE_SUCCESS = "GET_SHIPPING_FEE_SUCCESS";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const GET_ONE_ORDER_SUCCESS = "GET_ONE_ORDER_SUCCESS";

export const DELETE_ORDER_PRODUCT = "DELETE_ORDER_PRODUCT";
export const DELETE_ORDER_PRODUCT_SUCCESS = "DELETE_ORDER_PRODUCT_SUCCESS"; 

export const ON_CHANGE_LANG = "ON_CHANGE_LANG";

export const GET_ID_BUTTON_TABS = "GET_ID_BUTTON_TABS"

export const SHOW_LOADER = "SHOW_LOADER"

export const SHOW_POPUP = "SHOW_POPUP"

export const SHOW_LOADING_ADDTOCART = "SHOW_LOADING_ADDTOCART"

export const PRODUCT_SEARCH_KEYWORD = "PRODUCT_SEARCH_KEYWORD"

export const GET_DELETE_CART = "GET_DELETE_CART"
