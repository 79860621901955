import {applyMiddleware, compose } from "redux";
import {configureStore, Tuple} from "@reduxjs/toolkit";
import rootReducer from "../reducers/index";
import createSagaMiddleware from "redux-saga";
import apiSaga from "../sagas/saga";
import { analyticMiddleware } from '../middleware/index';

const initialiseSagaMiddleware = createSagaMiddleware();


const store = configureStore({
  reducer: rootReducer,
  middleware: () => new Tuple(initialiseSagaMiddleware, analyticMiddleware),
});

initialiseSagaMiddleware.run(apiSaga);

export default store;