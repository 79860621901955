import React from "react";
import {
  Avatar,
  Link,
  Grid,
  Paper,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../api.js";
import { API_URL_V2 } from "../_config/api.config.js";

function VerifiedOTP() {
  const navigate = useNavigate();
  const paperStyle = {
    padding: 20,
    height: "600px",
    width: 310,
    margin: "auto",
  };
  const gridStyle = {
    backgroundImage: 'url("/images/coca-cola-background.png")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    padding: "20px",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const initialValues = {
    otp: "",
  };
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .required("OTP không được để trống"),
  });

  const userEmail = localStorage.getItem("userEmail");
  console.log(userEmail);

  const onSubmit = async (formData, props) => {
    console.log("form submitttttttt");
    console.log(formData);
    let body = new FormData();
    body.append("email", userEmail);
    body.append("otp", formData.otp);
    const response = await post(`${API_URL_V2}/verified-otp`, body, true);
    console.log(response);
    if (response.success) {
      navigate("/reset-password");
    } else {
      console.log("Verify OTP Failed");
    }
    props.setSubmitting(false);
  };

  const maskEmail = (email) => {
    const [name, domain] = email.split("@");
    const maskedName = name.slice(0, 3) + "*".repeat(name.length - 3);
    return `${maskedName}@${domain}`;
  };

  return (
    <Grid style={gridStyle}>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={{ backgroundColor: "red" }}>
            <LockIcon />
          </Avatar>
          <h2>Quên mật khẩu</h2>
        </Grid>
        <h3 style={{display: "flex", flexDirection: "column"}}>
          Tài khoản
          <span>{maskEmail(userEmail)}</span>
        </h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Field
                as={TextField}
                margin="normal"
                label="OTP"
                name="otp"
                placeholder="Nhập mã OTP"
                fullWidth
                required
                helperText={<ErrorMessage name="otp" />}
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={props.isSubmitting}
              >
                Xác nhận
              </Button>
            </Form>
          )}
        </Formik>

        <Typography
          sx={{
            marginTop: "10px",
          }}
        >
          {" "}
          Quay lại trang{" "}
          <Link href="/login" style={{ textDecoration: "none" }}>
            Đăng nhập
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default VerifiedOTP;
