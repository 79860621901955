import React from "react";
import {
  Avatar,
  Link,
  Grid,
  Paper,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../api.js";
import { API_URL_V2 } from "../_config/api.config.js";

function SendOTP() {
  const navigate = useNavigate();
  const paperStyle = {
    padding: 20,
    height: "600px",
    width: 280,
  };
  const gridStyle = {
    backgroundImage: 'url("/images/coca-cola-background.png")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    padding: "20px",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Nhập email hợp lệ!")
      .required("Email không được để trống"),
  });
  const onSubmit = async (formData, props) => {
    console.log("form submitttttttt");
    console.log(formData);
    let body = new FormData();

    body.append("email", formData.email);

    const response = await post(`${API_URL_V2}/send-otp`, body, true);
    console.log(response);
    if (response.success) {
      navigate("/verify-otp");
      localStorage.setItem("userEmail", response.email);
    } else {
      console.log("Send OTP Failed");
    }
    props.setSubmitting(false);
  };
  return (
    <Grid style={gridStyle}>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={{ backgroundColor: "red" }}>
            <LockIcon />
          </Avatar>
          <h2>Quên mật khẩu</h2>
        </Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Field
                as={TextField}
                margin="normal"
                label="Email"
                name="email"
                placeholder="Nhập Email"
                fullWidth
                required
                helperText={<ErrorMessage name="email" />}
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={props.isSubmitting}
              >
                Xác nhận
              </Button>
            </Form>
          )}
        </Formik>

        <Typography
          sx={{
            marginTop: "10px",
          }}
        >
          Quay lại trang <Link href="/login" style={{textDecoration: "none"}}>Đăng nhập</Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default SendOTP;
