import { React } from "react";
import { useNavigate } from "react-router";
import {
  Avatar,
  Link,
  Grid,
  Paper,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { authenticateUser } from "../redux/actions/index.js";

import { Formik, Form, Field, ErrorMessage } from "formik";
import store from "../redux/stores/index.js";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../api.js";
import { API_URL_V2 } from "../_config/api.config.js";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
// import { Link } from 'react-router-dom';
function LoginPage() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const navigate = useNavigate();

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 280,
  };
  const gridStyle = {};
  const initialValues = {
    username: "",
    password: "",
    remember: false,
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email("Nhập email hợp lệ!")
      .required("Email không được để trống"),
    password: Yup.string().required("Mật khẩu không được để trống"),
  });

  
  const onSubmit = async (formData, props) => {
    // alert(JSON.stringify(formData, null, 2));
    console.log(store.getState());
    let body = new FormData();
    body.append("email", formData.username);
    body.append("password", formData.password);
    const response = await post(`${API_URL_V2}/login`, body, true);
    console.log(response);
    if (response.success) {
      const cookies = new Cookies(null, { path: "/" });
      cookies.set("token", response.token, {
        expires: new Date(Date.now() + 72 * 60 * 60 * 1000),
      });
      console.log(response.token);
      let body = new FormData();
      body.append("token", response.token);
      dispatch(authenticateUser(body));
      toast.success("Đăng nhập thành công!");
    } else {
      toast.error("Email hoặc mật khẩu không đúng!");
    }

    props.setSubmitting(false);
  };
  return (
    <Grid style={gridStyle}>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={{ backgroundColor: "red" }}>
            <LockIcon />
          </Avatar>
          <h2>Đăng nhập</h2>
        </Grid>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Field
                as={TextField}
                margin="normal"
                label="Email"
                name="username"
                placeholder="Nhập tên đăng nhập hoặc email"
                fullWidth
                required
                helperText={<ErrorMessage name="username" />}
              />

              <Field
                as={TextField}
                margin="normal"
                label="Mật khẩu"
                name="password"
                placeholder="Nhập mật khẩu"
                type="password"
                fullWidth
                required
                helperText={<ErrorMessage name="password" />}
              />
              <Field
                as={FormControlLabel}
                control={<Checkbox color="primary" defaultChecked />}
                label="Ghi nhớ đăng nhập"
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                disabled={props.isSubmitting}
              >
                Đăng nhập
              </Button>
            </Form>
          )}
        </Formik>

        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "10px",
          }}
        >
          Bạn chưa có tài khoản? <Link href="/register" style={{textDecoration: "none"}}>Đăng ký</Link>
        </Typography>

        <Typography
          sx={{
            marginTop: "10px",
            marginLeft: "10px",
          }}
        >
          Bạn quên mật khẩu? <Link href="/send-otp" style={{textDecoration: "none"}}>Quên mật khẩu</Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default LoginPage;
