import React from "react";
import { Avatar, Grid, Paper, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";

function ConfirmEmail() {
  const navigate = useNavigate();
  const paperStyle = {
    padding: 20,
    minWidth: 280,
    height: "400px",
  };
  const gridStyle = {
    backgroundImage: 'url("/images/coca-cola-background.png")',
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    padding: "20px",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
  };

  const emailConfirm = localStorage.getItem("emailConfirm");

  return (
    <Grid style={gridStyle}>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={{ backgroundColor: "red" }}>
            <LockIcon />
          </Avatar>
          <h2>Đăng kí</h2>
        </Grid>

        <Typography variant="h5" style={{display: "flex", flexDirection: "column"}}>
          Vui lòng kiểm tra email (mục spam)
          <b>{emailConfirm}</b>
          để xác nhận thông tin tài khoản
        </Typography>
      </Paper>
    </Grid>
  );
}

export default ConfirmEmail;
