import Cookies from "universal-cookie";

var Auth = {

  init: () => {
    const cookie = new Cookies();
    const token = cookie.get("token", {doNotParse: true});
    localStorage.setItem("token", token);
  },

  isAuth: () => {
    return localStorage.getItem("token") ? true : false;
  },

  set: () => {
    const cookie = new Cookies();
    const token = cookie.get("token", {doNotParse: true});
    localStorage.setItem("token", token);
  },

  get: () => {
    return {
      token: localStorage.getItem("token"),
    };
  }
};

export default Auth;
